import React, { useState, useEffect } from 'react';
import connectToStore from '../connect';
import Dialog from './Dialog';
import { Avatar } from 'antd';

const FBAuth = connectToStore((props) => {
  let data = useState({}).toState({});
  let authDialog = {};
  useEffect(() => {
    window.firebase.auth().onAuthStateChanged(
      function (user) {
        if (user) {
          var displayName = user.displayName;
          var email = user.email;
          var emailVerified = user.emailVerified;
          var photoURL = user.photoURL;
          var uid = user.uid;
          var phoneNumber = user.phoneNumber;
          var providerData = user.providerData;
          user.getIdToken().then(function (accessToken) {
            let u = {
              displayName: displayName,
              email: email,
              emailVerified: emailVerified,
              phoneNumber: phoneNumber,
              photoURL: photoURL,
              uid: uid,
              accessToken: accessToken,
              providerData: providerData,
            };
            data.set({ user: u });
            props.dispatch('setPublicUser', u);
            authDialog.close();
          });
        } else {
          data.set({ user: null });
          props.dispatch('setPublicUser', null);
          authDialog.close();
        }
      },
      function (error) {
        console.log(error);
        props.dispatch('setPublicUser', null);
      },
    );
  }, []);

  props.store.fbauth = () => {
    authDialog.open();
    window.startFbAuth();
  };
  

  props.store.fblogout = () => {
    window.firebase.auth().signOut();
  };
  return (
    <Dialog
      modal={authDialog}
      titre="Authentification"
      incprops={{ style: { maxWidth: 360, margin: '0 auto' } }}
    >
      <div id="firebaseui-auth-container"></div>
    </Dialog>
  );
});

FBAuth.Button = connectToStore((props) => {
  if (props.store.publicUser) {
    const { displayName, email, photoURL } = props.store.publicUser;
    return (
      <>
        <li className="nav-item">
          <a
            className="nav-link"
            style={{ cursor: 'pointer' }}
            onClick={(e) => props.to('/nouvelle-commande')}
          >
            Nouvelle Commande
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            style={{ cursor: 'pointer' }}
            onClick={(e) => props.to('/mes-commandes')}
          >
            Mes Commandes
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            style={{ cursor: 'pointer' }}
            onClick={(e) => props.to('/mes-adresses')}
          >
            Mes Adresses
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            style={{ cursor: 'pointer' }}
            onClick={(e) => props.to('/profile')}
          >
            Profile
          </a>
        </li>
        {/* <li className="nav-item hidemonmobile">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
              borderRight: '1px solid #ccc',
              borderLeft: '1px solid #ccc',
            }}
          >
            <Avatar src={photoURL} />
            <div style={{ marginLeft: 10 }}>
              <div style={{ marginBottom: -6 }}>{displayName}</div>
              <small>{email}</small>
            </div>
          </div>
        </li> */}
        <li className="nav-item">
          <a
            className="nav-link"
            style={{ cursor: 'pointer' }}
            onClick={(e) => props.store.fblogout()}
          >
            Deconnecter
          </a>
        </li>
      </>
    );
  } else {
    return (
      <li className="nav-item">
        <a
          className="nav-link"
          style={{ cursor: 'pointer' }}
          onClick={(e) => props.store.fbauth()}
        >
          Connecter
        </a>
      </li>
    );
  }
});

export default FBAuth;
