import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import connectToStore from '../connect';
import Loading from './Loading';
import PrixItem from './PrixItem';
import PrixCmd from './PrixCmd';
import TimesItem from './TimesItem';
import Dialog from './Dialog';
import CommandePrintViewCmd from '../page/CommandePrintViewCmd';
import useInterval from '../store/useInterval';

/**
 * @author
 * @function CommandeShowServeur
 **/

const CommandeShowServeur = (props) => {
  let item = props.item;
  let state = props.state;
  let list = item.list;
  let groups = list.reduce((g, v) => {
    if (!g.find((elm) => elm.id === v.id_groupeservable)) {
      g.push({
        titre: v.titre_groupeservable,
        id: v.id_groupeservable,
      });
    }
    return g;
  }, []);

  item.$api = (apiName, meta = {}) => {
    item.$loading = true;
    state.set({});
    return props.store.api(apiName, { meta, cmd: item }).then((res) => {
      item.$loading = false;
      if (res && res.cmd)
        for (let x in res.cmd) {
          item[x] = res.cmd[x];
        }
      state.set({});
      return item;
    });
  };
  item.setState = (state) => item.$api('commandesetstate', { state });
  item.save = () => item.$api('saveCommande');
  item.edit = () => props.to(`/app/commande/edit/${item.id}`);
  item.print = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf('android') > -1;
    if (isAndroid && props.store.user && props.store.user.imprimer) {
      window.firebase
        .database()
        .ref('printer/' + props.store.user.imprimer)
        .push(JSON.parse(JSON.stringify(item)));
    } else {
      [].print(
        <CommandePrintViewCmd
          logo={props.store.data.config.logo}
          item={item}
        />,
      );
    }
  };
  item.payer = () => {};
  item.remise_type = item.remise_type || '%';
  item.remise_value = item.remise_value || 0;
  item.bind = (name) => {
    return {
      value: item[name] || '',
      onChange: (e) => {
        item[name] = e.target.value || '';
        state.set({});
      },
    };
  };
  const update = (meta) => {
    return item.$api('saveCommande', meta);
  };
  let dialogRemise = {};
  let dialogPaiement = {};
  const Terminer = () => {
    item.setState('Payée');
  };
  return (
    <div
      className={
        'CommandeShow ' +
        (props.index === 0 ? ' CommandeShow-active' : '') +
        (item.alertClass ? ' CommandeShow-alert' : '')
      }
      id={'cmd-' + item.id}
      style={{ position: 'relative' }}
    >
      {item.$loading ? <Loading /> : null}
      <div className="CommandeShowheader">
        <div className="commandeid">{item.num}</div>
        <div className="tablename">
          <div>{item.table && item.table.titre}</div>
          <small>{item.name_user}</small>
        </div>
      </div>
      {item.tablette ? (
        <div
          onClick={(e) => item.edit()}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 100,
          }}
        >
          En tablette : {item.tablette.titre}
        </div>
      ) : (
        <>
          <div className="CommandeShowbody">
            {groups.map((groupe) => (
              <div key={groupe.id}>
                <div className="groupname">{groupe.titre}</div>
                {list
                  .filter((elm) => elm.id_groupeservable === groupe.id)
                  .map((elm, i) => (
                    <CommandeShowServeurItem
                      to={props.to}
                      update={update}
                      key={i}
                      item={elm}
                      cmd={item}
                      state={state}
                    />
                  ))}
              </div>
            ))}
          </div>
          <hr />
          <PrixCmd cmd={item} />
          <hr />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              {
                default: (
                  <button
                    className={'btn btn-sm btn-dark'}
                    onClick={(e) => item.edit()}
                  >
                    Modifier
                  </button>
                ),
                Prêt: (
                  <button
                    className={'btn btn-sm btn-dark'}
                    onClick={(e) => item.setState('Livré')}
                  >
                    Livrer Tous
                  </button>
                ),
                'En attente': (
                  <button
                    className={'btn btn-sm btn-dark'}
                    onClick={(e) => item.setState('Livré')}
                  >
                    Livrer Tous
                  </button>
                ),
                Livré: (
                  <>
                    {props.store.user &&
                    props.store.user.typeserveur == 'Serveur sans tables' ? (
                      <button
                        className={'btn btn-sm btn-primary'}
                        onClick={(e) => Terminer()}
                      >
                        Terminer
                      </button>
                    ) : props.store.user.role === 'responsable-back' ? null : (
                      <button
                        className={'btn btn-sm btn-primary'}
                        onClick={(e) => dialogPaiement.open()}
                      >
                        Payer
                      </button>
                    )}

                    {props.store.user &&
                    props.store.user.role === 'caissier' &&
                    props.store.user.remise ? (
                      <button
                        style={{ marginLeft: 10 }}
                        className={'btn btn-sm btn-dark'}
                        onClick={(e) => {
                          dialogRemise.open();
                        }}
                      >
                        Remise
                      </button>
                    ) : null}
                  </>
                ),
                Payée: <span className="badge badge-success">Payée</span>,
              }[
                item.list.length
                  ? item.actionserveur
                    ? item.state || 'default'
                    : item.state === 'Payée'
                    ? 'Payée'
                    : 'default'
                  : 'default'
              ]
            }
            <span style={{ flex: 1 }}></span>
            <button
              className={'btn btn-sm btn-dark'}
              onClick={(e) => item.print()}
            >
              Imprimer
            </button>
          </div>
        </>
      )}

      <Dialog
        modal={dialogPaiement}
        titre="Paiement"
        actions={[
          {
            titre: 'Valider',
            primary: true,
            onClick: (e) => {
              item.setState('Payée');
              dialogPaiement.close();
            },
          },
        ]}
      >
        <div className="form-group">
          <label>Type</label>
          <select className="form-control" {...item.bind('typepaiement')}>
            <option value="Espèce">Espèce</option>
            <option value="Chèque">Chèque</option>
            <option value="Chèque cadeau">Chèque cadeau</option>
            <option value="TPE">TPE</option>
            <option value="Bon resto">Bon resto</option>
            <option value="Points kool">Points kool</option>
          </select>
        </div>

        {item.typepaiement === 'Chèque' ||
        item.typepaiement === 'Chèque cadeau' ? (
          <div className="form-group">
            <label>N° de Chèque</label>
            <input
              className="form-control"
              placeholder="N° de Chèque"
              {...item.bind('numpaiement')}
            />
          </div>
        ) : null}
      </Dialog>

      <Dialog
        modal={dialogRemise}
        titre="Remise"
        actions={[
          {
            titre: 'Valider',
            primary: true,
            onClick: (e) => {
              dialogRemise.close();
              item.save();
            },
          },
        ]}
      >
        <div className="input-group">
          <input
            defaultValue={item.remise_value}
            // value={item.remise_value}
            // onChange={(e) => {
            //   if (e.target.value !== '') {
            //     item.remise_value = e.target.value;
            //     state.set({});
            //   }
            // }}
            onBlur={(e) => {
              item.remise_value = e.target.value * 1;
              state.set({});
            }}
            type="number"
            step={0.01}
            style={{ textAlign: 'right' }}
            className="form-control"
            aria-label="Text input with dropdown button"
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {item.remise_type || '%'}
            </button>
            <div className="dropdown-menu">
              <a
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  item.remise_type = '%';
                  state.set({});
                }}
                className="dropdown-item"
              >
                %
              </a>
              <a
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  item.remise_type = 'DT';
                  state.set({});
                }}
                className="dropdown-item"
              >
                DT
              </a>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Note</label>
          <input
            className="form-control"
            placeholder="Note"
            {...item.bind('remise_note')}
          />
        </div>
      </Dialog>
    </div>
  );
};

const CommandeShowServeurItem = (props) => {
  let { item, state, update, cmd } = props;
  let ss = useState({ alertatt: false }).toState();

  item.state = item.state || 'En attente';
  item.setState = (val) => {
    item.state = val;
    state.set({});
    update({
      target: 'item',
      item: { id: item.id, uid: item.uid, state: item.state },
      prop: 'state',
    });
  };

  useInterval(() => {
    ss.alertatt = !ss.alertatt;
    ss.set();
  }, 300);

  item.print = () => {
    [].printAC(item.titre, cmd.num, cmd.table.titre);
    // props.to(`/app/commande/printauto/${cmd.id}/${item.uid}`)
  };

  return (
    <div
      className={
        'CommandeShowItem ' +
        (ss.alertatt && item.state == 'Prêt'
          ? 'CommandeShowItem-alertatt'
          : 'CommandeShowItem-not-alertatt')
      }
      key={item.id}
    >
      <span>{item.qte}</span>
      <div>
        <div className="titleitemrow">
          <div>{item.titre}</div>
          <PrixItem item={item} />
        </div>

        {(item.supplements || []).filter((sup) => sup.checked).length ||
        (item.supplementspayants || []).filter((sup) => sup.checked).length ||
        (item.choix || []).filter((sup) => sup.checked).length ||
        item.emplacement ||
        (item.ingredientsclient || []).filter((sup) => sup.unchecked).length ? (
          <div className="divcomment">
            <ul style={{ paddingLeft: 22 }}>
              {(item.supplements || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'s' + sup.id}>{sup.titre_supplements}</li>;
                })}
              {(item.choix || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'s' + sup.id}>{sup.titre}</li>;
                })}
              {(item.supplementspayants || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'sp' + sup.id}>{sup.titre_supplements}</li>;
                })}
              {(item.ingredientsclient || [])
                .filter((sup) => sup.unchecked)
                .map((sup) => {
                  return (
                    <li key={'i' + sup.id}>
                      <del>{sup.titre_ingredients}</del>
                    </li>
                  );
                })}
              {item.emplacement ? (
                <li key={'i' + item.emplacement}>{item.emplacement}</li>
              ) : null}
            </ul>
          </div>
        ) : null}

        {item.comment ? <div className="divcomment">{item.comment}</div> : null}
        {(!item.terminer || item.state === 'Prêt') &&
        item.actionserveur &&
        ['Prêt', 'Livré'].includes(item.state) ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zoom: 0.75,
              margin: '6px 0 -11px 0',
            }}
          >
            <button
              onClick={(e) => item.setState('Prêt')}
              className={
                'btn btn-sm btn-' + (item.state === 'Prêt' ? 'success' : 'dark')
              }
            >
              En attente de livraison
            </button>
            <button
              onClick={(e) => item.setState('Livré')}
              className={
                'btn btn-sm btn-' +
                (item.state === 'Livré' ? 'success' : 'dark')
              }
            >
              Livré
            </button>
            <button
              onClick={(e) => item.print()}
              className={'btn btn-sm btn-dark'}
            >
              A.C.
            </button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zoom: 0.8,
              margin: '6px 0 -11px 0',
            }}
          >
            <span className="badge badge-warning">{item.state}</span>
            <button
              onClick={(e) => item.print()}
              className={'btn btn-sm btn-dark'}
            >
              A.C.
            </button>
          </div>
        )}
        <TimesItem item={item} />
      </div>
    </div>
  );
};

export default connectToStore(CommandeShowServeur);
