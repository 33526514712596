import { Spin, Switch } from 'antd';
import React, { Component } from 'react';
import connectToStore from '../connect';

class Servables extends Component {
  state = {
    loading: false,
    data: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.store
      .api('getServablesList', { user: this.props.store.user })
      .then(({ data }) => {
        this.setState({ data: data.map(this.initItem), loading: false });
      });
  }

  initItem = (item) => {
    item.onChange = (checked) => {
      const { data } = this.state;
      item.active = checked ? 1 : 0;
      this.setState({ data });
      this.props.store.api('setServableActive', {
        id: item.id,
        active: item.active,
      });
    };
    return item;
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div style={{ background: '#fff', padding: '15px 30px' }}>
        <Spin spinning={loading}>
          <table className="table table-striped table-bordered">
            <tr>
              <th style={{ width: '1px' }}>ID</th>
              <th style={{ width: '1px' }}>Active</th>
              <th>Servable</th>
            </tr>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                  <Switch checked={!!item.active} onChange={item.onChange} />
                </td>
                <td>{item.titre}</td>
              </tr>
            ))}
          </table>
        </Spin>
      </div>
    );
  }
}

export default connectToStore(Servables);
