import React from 'react';
import connectToStore from '../connect';

/**
 * @author
 * @function Actions
 **/

const ItemAction = connectToStore((props) => {
  var data = {
    titre: '',
    to: '/app/',
  };

  if (typeof props.name === 'object') {
    data = props.name;
  } else {
    switch (props.name) {
      default:
        break;
      case 'newcmd':
        data.titre = 'Nouvelle commande';
        data.to = '/app/commande/new';
        break;
      case 'encours':
        if (props.store.activecommandes.length > 1) {
          data.titre =
            props.store.activecommandes.length + ' Commandes en cours';
        } else if (props.store.activecommandes.length) {
          data.titre = '1 Commande en cours';
        } else {
          data.titre = 'Commandes en cours';
        }
        data.to = '/app/commande/list';
        break;

      case 'historique':
        if (
          props.store.user.role === 'caissier' &&
          !props.store.user.historique
        )
          return null;
        data.titre = 'Historique';
        data.to = '/app/commande/historique';
        break;

      case 'serveur-newcmd':
        if (props.store.user.typeserveur == 'Pass') return null;
        data.titre = 'Nouvelle commande';
        data.to = '/app/serveur/commande/new';
        break;

      case 'serveur-encours':
        if (props.store.activecommandes.length > 1) {
          data.titre =
            props.store.activecommandes.length + ' Commandes en cours';
        } else if (props.store.activecommandes.length) {
          data.titre = '1 Commande en cours';
        } else {
          data.titre = 'Commandes en cours';
        }
        data.to = '/app/serveur/commande/list';
        break;

      case 'serveur-historique':
        data.titre = 'Historique';
        data.to = '/app/serveur/commande/historique';
        break;

      case 'caissier-newcmd':
        data.titre = 'Nouvelle commande';
        data.to = '/app/caissier/commande/new';
        break;

      case 'caissier-encours':
        if (props.store.activecommandes.length > 1) {
          data.titre =
            props.store.activecommandes.length + ' Commandes en cours';
        } else if (props.store.activecommandes.length) {
          data.titre = '1 Commande en cours';
        } else {
          data.titre = 'Commandes en cours';
        }
        if (props.store.user.role === 'admin') {
          data.to = '/app/admin/commande/list';
        } else {
          data.to = '/app/caissier/commande/list';
        }

        break;

      case 'caissier-historique':
        if (
          props.store.user.role === 'caissier' &&
          !props.store.user.historique
        )
          return null;
        data.titre = 'Historique';

        if (props.store.user.role === 'admin') {
          data.to = '/app/admin/commande/historique';
        } else {
          data.to = '/app/caissier/commande/historique';
        }
        break;
      case 'resp-historique':
        data.titre = 'Historique';
        data.to = '/app/' + props.store.user.role + '/commande/historique';
        break;
      case 'resp-newcmd':
        data.titre = 'Nouvelle commande';
        data.to = '/app/' + props.store.user.role + '/commande/new';
        if (props.store.user.role === 'responsable-back') return null;
        break;
      case 'poste-newcmd':
        data.titre = 'Nouvelle commande';
        data.to = '/app/poste/commande/new';
        break;

      case 'poste-encours':
        if (props.store.activecommandes.length > 1) {
          data.titre =
            props.store.activecommandes.length + ' Commandes en cours';
        } else if (props.store.activecommandes.length) {
          data.titre = '1 Commande en cours';
        } else {
          data.titre = 'Commandes en cours';
        }
        data.to = '/app/poste/commande/list';
        break;

      case 'resp-encours':
        if (props.store.activecommandes.length > 1) {
          data.titre =
            props.store.activecommandes.length + ' Commandes en cours';
        } else if (props.store.activecommandes.length) {
          data.titre = '1 Commande en cours';
        } else {
          data.titre = 'Commandes en cours';
        }
        data.to = '/app/' + props.store.user.role + '/commande/list';
        break;

      case 'poste-historique':
        data.titre = 'Historique';
        data.to = '/app/poste/commande/historique';
        break;
      case 'encaissement':
        if (
          props.store.user.role === 'caissier' &&
          !props.store.user.encaissement
        )
          return null;
        data.titre = 'Encaissement';
        if (props.store.user.role === 'admin') {
          data.to = '/app/admin/encaissement';
        } else {
          data.to = '/app/caissier/encaissement';
        }
        break;
      case 'demandeachat':
        data.titre = "Demande d'achat";
        data.to = '/app/poste/demande/achat';
        break;
      case 'gestiondesplats':
        data.titre = 'Gestion des plats';
        data.to = '/app/poste/gestion/plats';
        break;
      case 'gestiondesservables':
        data.titre = 'Gestion des servables';
        data.to = '/app/poste/gestion/servables';
        break;
    }
  }

  return (
    <button
      className="actionsitem btn btn-dark"
      onClick={(e) => props.to(data.to)}
    >
      {data.titre}
    </button>
  );
});

const Actions = (props) => {
  let list = props.list || [];
  return (
    <div className="actionscontainer">
      {list.map((item, i) => (
        <ItemAction key={i} name={item} />
      ))}
    </div>
  );
};

export default Actions;
