import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import connectToStore from '../connect';
import Layout from './../com/Layout';

/**
 * @author
 * @function PublicProfile
 **/

const PublicProfile = (props) => {
  return (
    <Layout>
      <hr />
      <div className="titleitemrow" style={{ justifyContent: 'space-between' }}>
        <h1>Profile</h1>
      </div>
      <div className="d-flex flex-wrap">
        <div style={{ maxWidth: 400 }}>
          <PublicProfileDetails />
        </div>
        <div style={{ flex: 1 }}>
          <PublicProfileCarteKool />
        </div>
      </div>
    </Layout>
  );
};

const PublicProfileCarteKool = connectToStore((props) => {
  const { carte_type, carte_etat, carte_date, uid } = props.store.publicUser;
  const [state, setState] = useState({
    loading: false,
    demande: false,
    accept: false,
  });
  const demandeCarteKool = () => {
    setState({ loading: true });
    props.store.api('demandercartekool', { uid }, (rep) => {
      setState({ loading: false });
      if (rep) {
        props.dispatch('setPublicUserInfo', {
          ...props.store.publicUser,
          carte_type: rep.carte_type,
          carte_etat: rep.carte_etat,
          carte_date: rep.carte_date,
        });
      }
    });
  };

  const predemandeCarteKool = () => {
    setState({ ...state, demande: true });
  };

  return (
    <Spin spinning={state.loading}>
      <div>
        {carte_etat ? (
          <div className="d-flex justify-content-center">
            <CarteKool />
          </div>
        ) : state.demande ? (
          <div style={{ padding: '15px 30px' }}>
            <h1>Demande carte kool</h1>
            <p>....</p>
            <p>
              Veuillez cliquer sue ce lien pour lire les termes & conditions:
              <a href="#">termes & conditions</a>
            </p>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={state.accept}
                  onChange={(e) =>
                    setState({ ...state, accept: !!e.target.checked })
                  }
                />
                Accepter les termes et conditions
              </label>
            </div>
            <button
              className="btn btn-primary"
              onClick={demandeCarteKool}
              disabled={!state.accept}
            >
              Valider
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary" onClick={predemandeCarteKool}>
              Demander carte kool
            </button>
          </div>
        )}
      </div>
    </Spin>
  );
});

const PublicProfileDetails = connectToStore((props) => {
  const { displayName, tel, uid } = props.store.publicUser;
  const [state, setState] = useState({ displayName, tel, uid, loading: false });
  useEffect(() => {
    setState({ ...state, displayName, tel, uid });
  }, [
    props.store.publicUser.uid,
    props.store.publicUser.tel,
    props.store.publicUser.displayName,
  ]);
  const bind = (name) => {
    return {
      value: state[name] || '',
      onChange: (e) => {
        let value = e.target.value;
        setState({ ...state, [name]: value });
      },
    };
  };

  const save = () => {
    setState({ ...state, loading: true });
    props.store.api('setUserInfos', state, () => {
      setState({ ...state, loading: false });
      props.dispatch('setPublicUserInfo', {
        ...props.store.publicUser,
        displayName: state.displayName,
        tel: state.tel,
      });
    });
  };

  return (
    <Spin spinning={state.loading}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Nom & prénom</label>
            <input
              className="form-control"
              placeholder="Nom & prénom"
              {...bind('displayName')}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Numéro de téléphone</label>
            <input
              className="form-control"
              placeholder="Numéro de téléphone"
              {...bind('tel')}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Points de fidélité</label>
            <input
              className="form-control"
              readonly="readonly"
              disabled="disabled"
              placeholder="Points de fidélité"
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Solde Kool:</label>
            <input
              className="form-control"
              readonly="readonly"
              disabled="disabled"
              placeholder="Solde Kool:"
            />
          </div>
        </div>
        <div className="col-12">
          <button className="btn btn-primary mt-2" onClick={save}>
            Enregistrer
          </button>
        </div>
      </div>
    </Spin>
  );
});

const CarteKool = connectToStore((props) => {
  const {
    carte_type,
    carte_etat,
    carte_date,
    uid,
    displayName,
    tel,
  } = props.store.publicUser;

  if (carte_etat === 'En attente') return (
    <div>Votre carte kool est en attente de validation</div>
  );

  return (
    <div className="carte-kool">
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 25 }}>Carte Kool</div>
        <div>
          <label>Nom & prénom</label>
          <span>{displayName}</span>
        </div>
        <div>
          <label>Num tel</label>
          <span>{tel}</span>
        </div>
      </div>
      <div>
        <img
          style={{ borderRadius: 5 }}
          src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Ferp.koolfood.tn%2F&chs=150x150&choe=UTF-8&chld=L|2"
          alt=""
        />
      </div>
    </div>
  );
});

export default connectToStore(PublicProfile);
