import React, { useEffect, useState } from 'react';
import connectToStore from '../connect';
import FBAuth from './FBAuth';

const Menu = (props) => {
  let [state, setState] = useState({
    alert: false,
    alerttimetimer: null,
  });
  state.set = (obj = {}) => {
    state = { ...state, ...obj };
    setState(state);
  };
  useEffect(() => {
    state.alerttimetimer = setInterval(() => {
      window.alerttimetimer = !window.alerttimetimer;
      state.set({ alert: window.alerttimetimer });
    }, 500);
    state.set();
    return () => clearInterval(state.alerttimetimer);
  }, []);

  const showFocusCmd = (cmd) => {
    const domCmd = document.getElementById('cmd-' + cmd.id);
    if (domCmd) {
      domCmd.scrollIntoView();
      let a = true;
      domCmd.classList.add('CommandeShow-alert');
      let ta = setInterval(() => {
        if (a) {
          domCmd.classList.remove('CommandeShow-alert');
          a = false;
        } else {
          domCmd.classList.add('CommandeShow-alert');
          a = true;
        }
      }, 150);
      setTimeout(() => {
        clearInterval(ta);
        domCmd.classList.remove('CommandeShow-alert');
      }, 2000);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light rightmenu">
      <div className="container">
        <a className="navbar-brand" onClick={(e) => props.to('/app/')}>
          <img
            style={{ height: 43, margin: '-20px 0px' }}
            src={props.store.data.config.logo.getUrl()}
          />
        </a>
        <a className="navbar-brand" onClick={(e) => props.to('/app/')}>
          {props.store.data.config.titre}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className={
              'navbar-nav mr-auto ' +
              (state.alert ? 'nav-item-notification' : '')
            }
          >
            {props.store.user ? (
              <>
                <li className="nav-item">
                  <a className="nav-link">
                    {props.store.user.nom}{' '}
                    <small>- {props.store.user.type}</small>{' '}
                  </a>
                </li>
                {props.store.notificationCommandes.length > 0 ? (
                  <li className="nav-item dropdown active nav-item-list">
                    <a
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {props.store.notificationCommandes.length} Commande
                      {props.store.notificationCommandes.length > 1
                        ? 's changés'
                        : ' changé'}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ minWidth: 300 }}
                    >
                      <a className="dropdown-item">Commandes changés</a>
                      <div className="dropdown-divider"></div>
                      {props.store.notificationCommandes.map((cmd, i) => (
                        <div
                          key={i + ':' + cmd.id}
                          onClick={(e) => {
                            props.dispatch('viderlist', cmd);
                            props.to(
                              '/app/' + props.store.user.role + '/commande/list'
                            );
                            setTimeout(() => {
                              showFocusCmd(cmd);
                            }, 500);
                          }}
                          className="dropdown-item CommandeShowheader-notification"
                        >
                          <div className="commandeid-notification">
                            {cmd.num}
                          </div>
                          <div className="tablename">
                            <div>{cmd.table && cmd.table.titre}</div>
                            <small>{cmd.name_user}</small>
                          </div>
                        </div>
                      ))}
                      <div className="dropdown-divider"></div>
                      <a
                        className="dropdown-item"
                        onClick={(e) => {
                          props.dispatch('viderlist');
                        }}
                      >
                        Vider la liste
                      </a>
                    </div>
                  </li>
                ) : null}
              </>
            ) : null}
          </ul>
          {props.offline || !props.store.user ? null : (
            <ul className="navbar-nav my-2 my-lg-0">
              {/*  */}

              {[
                'serveur',
                'caissier',
                'responsable-front',
                'responsable-back',
              ].includes(props.store.user.role) ? (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => props.store.printDialog.open()}
                  >
                    Config
                  </a>
                </li>
              ) : null}

              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => props.store.logout()}
                >
                  Déconnecter
                </a>
              </li>
            </ul>
          )}

          {props.store.user || !props.store.fbauth ? null : (
            <ul className="navbar-nav my-2 my-lg-0">
              <FBAuth.Button />
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default connectToStore(Menu);
