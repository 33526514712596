import React, { useState } from 'react';
import connectToStore from '../connect';
import Tables from './Tables';
import Loading from './Loading';
import Dialog from './Dialog';
import PrixCmd from './PrixCmd';
import { Checkbox, Icon, Radio } from 'antd';
import Tablettes from './Tablettes';
import { Prompt } from 'react-router-dom';
import CommandePrintViewCmd from '../page/CommandePrintViewCmd';
import PrixItem from './PrixItem';
import { SelectAdresse } from '../page/PublicMesAdresses';
/**
 * @author
 * @function CommandeForm
 **/

const CommandeForm = (props) => {
  const isPublic = !!props.isPublic;
  var state = props.store.createState(
    useState({ groupe: props.store.data.groupes[0], changed: false }),
  );

  const { api } = props.store;
  if (!props.cmd.table && !isPublic)
    return (
      <Tables
        onSelect={(table, u) => {
          if (u) {
            props.to(u);
          } else {
            props.cmd.set({ table });
          }
        }}
      />
    );

  if (props.cmd.chosetablette && !isPublic)
    return (
      <Tablettes
        onSelect={(tablette, u) => {
          if (u) {
            props.to(u);
          } else {
            props.cmd.set({ tablette, chosetablette: false });
          }
        }}
      />
    );

  let commentDialog = {};
  let detailDialog = {};
  let saveDialog = {};

  const add = (servable) => {
    if (!servable.active) return;
    props.cmd.list = props.cmd.list || [];
    let servinlist = props.cmd.list
      .filter((serv) => !serv.terminer)
      .find((serv) => serv.id === servable.id);
    if (servinlist) {
      servinlist.qte += 1;
    } else {
      props.cmd.list.push({
        ...JSON.parse(JSON.stringify(servable)),
        qte: 1,
        uid: (
          Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        ).toUpperCase(),
      });
    }
    state.set({ changed: true });
    props.cmd.set({});
  };

  const saveCommande = (obj = {}, validation) => {
    if (
      isPublic &&
      (!props.cmd.client_tel ||
        (!props.cmd.adresse_livraison && !props.cmd.id_adresse))
    ) {
      state.set({ validation });
      saveDialog.open();
    } else {
      state.set({ validation: false });
      props.cmd.set({ $loading: true });
      saveDialog.close();
      return props.store
        .api(isPublic ? 'saveCommandeB2C' : 'saveCommande', {
          cmd: { ...props.cmd, ...obj },
          user: isPublic ? props.store.publicUser : null,
        })
        .then((res) => {
          state.set({ changed: false });
          if (!props.cmd.id && res.cmd.id) {
            props.to('/app/commande/edit/' + res.cmd.id);
          }
          props.cmd.set({ ...res.cmd, $loading: false });
        });
    }
  };

  const annulerCommande = () => {
    if (!window.confirm('Voulez vous vraiment annuler cette commande?')) return;
    props.cmd.set({ $loading: true });
    return props.store
      .api('annulerCommande', { cmd: { ...props.cmd } })
      .then((res) => {
        props.cmd.set({ ...res.cmd, $loading: false });
        state.set({ changed: false });
        props.to('/app/');
      });
  };

  props.cmd.list = props.cmd.list || [];
  props.cmd.list = props.cmd.list.map((item, i) => {
    item.setQte = (qte) => {
      if (isPublic && !props.store.publicUser) return;
      isLastUpdate(item);
      if (qte > 0) {
        item.qte = qte;
      } else {
        props.cmd.list = props.cmd.list.filter((elm) => elm.uid !== item.uid);
      }
      props.cmd.set({});
    };
    item.setComment = () => {
      state.current = item;
      state.set({});
      commentDialog.open();
    };
    item.dupliquer = () => {
      props.cmd.list.splice(i + 1, 0, {
        ...JSON.parse(JSON.stringify(item)),
        times: {},
        state: null,
        qte: 1,
        uid: (
          Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        ).toUpperCase(),
      });
      props.cmd.set({});
      state.set({});
    };
    return item;
  });

  props.store.data.servables = props.store.data.servables.map((servable) => {
    servable.getQte = () => {
      let val = props.cmd.list.find((elm) => elm.uid === servable.uid);
      if (val) return val.qte;
      return 0;
    };
    return servable;
  });

  const showDetail = (servable) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    state.current = servable;
    state.set({});
    detailDialog.open();
  };

  const Ignorer = () => {
    props.cmd.set({
      client_nom: '',
      client_prenom: '',
      client_tel: '',
      clientview: 'Ignorer',
    });
  };
  const ValiderInscription = () => {
    if (
      !props.cmd.client_nom ||
      !props.cmd.client_prenom ||
      !props.cmd.client_tel
    )
      return alert('Veuillez remplir tous les champs');
    api('loadclientdata', { tel: props.cmd.client_tel }, function (rep) {
      if (rep) {
        alert('Ce numéro déjà utilisé');
      } else {
        props.cmd.set({ clientview: 'ValiderInscription' });
      }
    });
  };
  const Valider = () => {
    api('loadclientdata', { tel: props.cmd.client_tel }, function (rep) {
      if (rep) {
        props.cmd.set({
          client_nom: rep.client_nom,
          client_prenom: rep.client_prenom,
          client_tel: rep.client_tel,
          clientview: 'Valider',
        });
      } else {
        alert('N° téléphone introuvable!');
      }
    });
  };

  if (props.tablette && !isPublic) {
    if (!props.cmd.clientview || props.cmd.clientview === 'register') {
      return (
        <div className="layoutcol" style={{ justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="formclientauth">
              <div className="row">
                <div className="col-12">
                  <h1 className="h3 mb-3 font-weight-normal">Inscription</h1>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Nom</label>
                    <input
                      type="text"
                      className="form-control"
                      {...props.cmd.bind('client_nom')}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Prénom</label>
                    <input
                      type="text"
                      className="form-control"
                      {...props.cmd.bind('client_prenom')}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>N° Téléphone</label>
                    <input
                      type="text"
                      className="form-control"
                      {...props.cmd.bind('client_tel')}
                    />
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={(e) => ValiderInscription()}
                  >
                    Valider
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={(e) => Ignorer()}
                  >
                    Ignorer
                  </button>
                </div>
              </div>
            </div>
            <div className="formclientauth">
              <div className="row">
                <div className="col-12">
                  {' '}
                  <h1 className="h3 mb-3 font-weight-normal">Déjà inscrit ?</h1>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>N° Téléphone</label>
                    <input
                      type="text"
                      className="form-control"
                      {...props.cmd.bind('client_tel')}
                    />
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={(e) => Valider()}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  const getPrixCmd = (cmd) => {
    let total = 0;
    (state.current.supplementspayants || []).map((sup) => {
      total += sup.prix;
    });
    return 0;
  };

  const isLastUpdate = (elm) => {
    elm.lastupdate = new Date().getTime();
  };

  return (
    <div className="layoutcol">
      {!isPublic && (
        <Prompt
          when={state.changed}
          message={(location) =>
            `Si vous quitter, les modifications ne seront pas enregistrées \nCliquer "Confirmer" pour quitter sons enregistrement, \nou Cliquer "Annuler" et enregistrer avant de quitter.`
          }
        />
      )}
      <Dialog
        modal={saveDialog}
        titre="Informations de livraison"
        actions={[
          {
            titre: 'Fermer',
            onClick: (e) => saveDialog.close(),
          },
          {
            titre: 'Valider',
            primary: true,
            onClick: (e) => {
              console.log('Valider click');
              saveCommande({}, true);
            },
          },
        ]}
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>N° Téléphone</label>
              <input
                type="text"
                className={
                  'form-control ' +
                  (state.validation &&
                  (props.cmd.client_tel || '').replace(/[^0-9]+/g, '').length <
                    8
                    ? 'is-invalid'
                    : '')
                }
                {...props.cmd.bind('client_tel')}
              />
            </div>
          </div>

          <div className="col-12">
            <SelectAdresse
              value={props.cmd.id_adresse}
              onChange={(id_adresse) => props.cmd.set({ id_adresse })}
              className={
                'form-control ' +
                (state.validation && !props.cmd.id_adresse ? 'is-invalid' : '')
              }
            />
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Type de paiement</label>
              <select
                className="form-control"
                {...props.cmd.bind('type_paiement')}
              >
                <option value="Espèces">Espèces</option>
                <option value="Chèque">Chèque</option>
                <option value="Chèque cadeau">Chèque cadeau</option>
                <option value="TPE">TPE</option>
                <option value="Bon resto">Bon resto</option>
                <option value="Points kool">Points kool</option>
              </select>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="listGroupes">
        {(!isPublic || props.store.publicUser) && (
          <div
            className="groupitem active2"
            onClick={(e) => state.set({ showcmd: true })}
          >
            Commande
          </div>
        )}
        {props.tablette || isPublic ? null : (
          <div
            className="groupitem active1"
            onClick={(e) => {
              state.set({ showcmd: false });
              props.cmd.set({ table: null });
            }}
          >
            {props.cmd.table && props.cmd.table.titre}
          </div>
        )}

        {props.store.data.groupes.map((group, ig) => (
          <div
            key={ig}
            className={
              'groupitem groupitem1 ' +
              (group.id === (state.groupe && state.groupe.id) ? 'active' : '')
            }
            onClick={(e) => state.set({ groupe: group, showcmd: false })}
          >
            {group.titre}
          </div>
        ))}
      </div>

      <Dialog
        modal={commentDialog}
        titre={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Commentaire</span>
            <span>{state.current && <PrixItem item={state.current} />}</span>
          </div>
        }
        actions={[
          {
            titre: 'Valider',
            primary: true,
            onClick: (e) => {
              commentDialog.close();
            },
          },
        ]}
      >
        {state.current ? (
          <div>
            <h4>{state.current.titre}</h4>
            <hr />
            <div className="row">
              <div className="col-3">
                <b>Ingredients :</b>
                {(state.current.ingredientsclient || []).map((elm) => (
                  <div key={elm.id}>
                    <Checkbox
                      checked={!elm.unchecked}
                      onChange={(e) => {
                        elm.unchecked = !e.target.checked;
                        isLastUpdate(state.current);
                        state.set();
                      }}
                    >
                      {elm.titre_ingredients}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <div className="col-3">
                <b>Suppléments Gratuits :</b>
                {(state.current.supplements || []).map((sup) => (
                  <div key={sup.id}>
                    <Checkbox
                      checked={sup.checked}
                      onChange={(e) => {
                        sup.checked = e.target.checked;
                        isLastUpdate(state.current);
                        state.set();
                      }}
                    >
                      {sup.titre_supplements}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <div className="col-3">
                <b>Suppléments Payants :</b>
                {(state.current.supplementspayants || []).map((sup) => (
                  <div key={sup.id}>
                    <Checkbox
                      checked={sup.checked}
                      onChange={(e) => {
                        sup.checked = e.target.checked;
                        isLastUpdate(state.current);
                        state.set();
                      }}
                    >
                      {sup.titre_supplements}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <div className="col-3">
                <b>Choix :</b>
                {(state.current.choix || []).map((choix) => (
                  <div key={choix.id}>
                    <Checkbox
                      checked={choix.checked}
                      onChange={(e) => {
                        choix.checked = e.target.checked;
                        isLastUpdate(state.current);
                        state.set();
                      }}
                    >
                      {choix.titre}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <Radio.Group
                  onChange={(e) => {
                    state.current.emplacement = e.target.value;
                    isLastUpdate(state.current);
                    state.set();
                  }}
                  value={state.current.emplacement || ''}
                >
                  <Radio value="">Sur place</Radio>
                  <Radio value="Emporter">À emporter</Radio>
                  <Radio value="Livraison">Livraison</Radio>
                </Radio.Group>
              </div>
            </div>
            <hr />
            {state.inputText('comment', 'Commenatire', {
              target: state.current,
              textarea: 5,
            })}
          </div>
        ) : null}
      </Dialog>
      <div
        style={{ flex: 1, padding: 5, display: 'flex' }}
        className={state.showcmd ? 'showcmd' : ''}
      >
        {(!isPublic || props.store.publicUser) && (
          <div>
            <div className="commandecontainer">
              {props.cmd.$loading ? <Loading /> : null}
              <div className="commandeheader">
                <i className="fa fa-file" style={{ marginRight: 11 }}></i>
                Commande
                {props.cmd.num ? ' : ' + props.cmd.num : null}
              </div>
              {props.store.data.groupes
                .filter(
                  (groupe) =>
                    !!props.cmd.list.find(
                      (elm) => elm.id_groupeservable === groupe.id,
                    ),
                )
                .map((groupe) => (
                  <div key={groupe.id} className="groupeitem">
                    <h5 style={{ paddingLeft: 6, margin: '5px 0' }}>
                      {groupe.titre}
                    </h5>
                    <div>
                      {props.cmd.list
                        .filter((elm) => elm.id_groupeservable === groupe.id)
                        .map((item) => (
                          <div key={item.id} className="platitem">
                            <div>
                              <b>{item.qte}</b> : {item.titre}
                            </div>
                            <div>
                              <small>
                                <ul>
                                  {(item.supplements || [])
                                    .filter((sup) => sup.checked)
                                    .map((sup) => {
                                      return (
                                        <li key={'s' + sup.id}>
                                          {sup.titre_supplements}
                                        </li>
                                      );
                                    })}
                                  {(item.choix || [])
                                    .filter((sup) => sup.checked)
                                    .map((sup) => {
                                      return (
                                        <li key={'s' + sup.id}>{sup.titre}</li>
                                      );
                                    })}
                                  {(item.supplementspayants || [])
                                    .filter((sup) => sup.checked)
                                    .map((sup) => {
                                      return (
                                        <li key={'sp' + sup.id}>
                                          {sup.titre_supplements}
                                        </li>
                                      );
                                    })}
                                  {(item.ingredientsclient || [])
                                    .filter((sup) => sup.unchecked)
                                    .map((sup) => {
                                      return (
                                        <li key={'i' + sup.id}>
                                          <del>{sup.titre_ingredients}</del>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </small>
                            </div>
                            {item.terminer ? null : (
                              <div>
                                <button
                                  style={{ zoom: 0.5, margin: '0 10px' }}
                                  onClick={(e) => item.setQte(item.qte + 1)}
                                  className="btn btn-sm btn-outline-success"
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                                <button
                                  style={{ zoom: 0.5, margin: '0 10px' }}
                                  onClick={(e) => item.setQte(item.qte - 1)}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <button
                                  style={{ zoom: 0.5, margin: '0 10px' }}
                                  onClick={(e) => item.setComment()}
                                  className={
                                    'btn btn-sm btn' +
                                    (item.comment ? '' : '-outline') +
                                    '-dark'
                                  }
                                >
                                  <i className="fa fa-comment"></i>
                                </button>
                                <button
                                  style={{ zoom: 0.5, margin: '0 10px' }}
                                  onClick={(e) => item.dupliquer()}
                                  className="btn btn-sm btn-outline-dark"
                                >
                                  <i className="fa fa-copy"></i>
                                </button>
                                <button
                                  style={{ zoom: 0.5, margin: '0 10px' }}
                                  onClick={(e) => item.setQte(0)}
                                  className="btn btn-sm btn-outline-dark"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              <span style={{ flex: 1 }}></span>
              <div className="commandefooter">
                <button
                  disabled={!props.cmd.list.length && !props.cmd.tablette}
                  className="btn btn-primary"
                  onClick={(e) => saveCommande()}
                >
                  <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                  {props.tablette
                    ? 'Terminer'
                    : props.cmd.id
                    ? 'Mise a jour'
                    : 'Valider'}
                </button>
                {['Pass', 'Serveur sans tables'].includes(
                  props.store.user && props.store.user.typeserveur,
                ) ||
                props.tablette ||
                isPublic ? null : (
                  <button
                    className="btn btn-default"
                    onClick={(e) => props.cmd.set({ chosetablette: true })}
                  >
                    <div>Tablette</div>
                    <small>
                      {props.cmd.tablette ? (
                        <span className="badge badge-success">
                          {props.cmd.tablette.titre}
                        </span>
                      ) : null}
                    </small>
                  </button>
                )}
                <PrixCmd cmd={props.cmd} priceOnly={true} />
              </div>
              {isPublic ? null : (
                <div className="commandefooter">
                  {props.store.user &&
                  ![
                    'responsable-front',
                    'responsable-back',
                    'serveur',
                  ].includes(props.store.user.role) &&
                  props.cmd.id &&
                  !props.tablette &&
                  (props.store.user.role !== 'caissier' ||
                    props.store.user.annulation) ? (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => annulerCommande()}
                    >
                      Annuler la commande
                    </button>
                  ) : null}
                  {props.cmd.id && !props.tablette ? (
                    <button
                      className="btn btn-default"
                      onClick={(e) =>
                        [].print(
                          <CommandePrintViewCmd
                            logo={props.store.data.config.logo}
                            item={props.cmd}
                          />,
                        )
                      }
                    >
                      Imprimer
                    </button>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="consumercontainer">
          <h4
            style={{
              margin: 7,
              borderBottom: '1px solid #cccccc',
              padding: '0 7px 7px 7px',
            }}
          >
            {state.groupe && state.groupe.titre}
          </h4>
          <div style={{ padding: 7 }} className="servablelist">
            {props.store.data.servables
              .filter(
                (servable) =>
                  servable.id_groupeservable ===
                  (state.groupe && state.groupe.id),
              )
              .map((servable) => (
                <div
                  key={servable.id}
                  className={
                    'servableitem' +
                    (servable.getQte() ? ' servableitem-selected' : '')
                  }
                  onClick={(e) => add(servable)}
                >
                  {servable.image ? (
                    <img src={servable.image.getUrl()} alt="true" />
                  ) : null}
                  <span>{servable.titre}</span>
                  {servable.getQte() ? (
                    <small>{servable.getQte()}</small>
                  ) : null}

                  {servable.active ? null : (
                    <div className="badgediv">
                      <span class="badge badge-danger">Non disponible</span>
                    </div>
                  )}
                  <div className="infoservable" onClick={showDetail(servable)}>
                    <Icon type="info-circle" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Dialog
        modal={detailDialog}
        titre={
          state.current ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{state.current.titre}</span>
              <span>
                {(state.current.prix_vente_u_ttc * 1 || 0).toFixed(3)}{' '}
                <small>
                  <sup>DT</sup>
                </small>
              </span>
            </div>
          ) : (
            ''
          )
        }
        actions={[
          {
            titre: 'Fermer',
            primary: true,
            onClick: (e) => {
              detailDialog.close();
            },
          },
        ]}
      >
        {state.current ? (
          <div className="row">
            <div className="col-6">
              <b>Ingredients</b>
              <ul>
                {(state.current.ingredientsclient || []).map((elm) => (
                  <li className="ucfirst" key={elm.id}>
                    {elm.titre_ingredients}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6">
              {state.current.image ? (
                <img
                  src={state.current.image.getUrl()}
                  alt="true"
                  style={{ width: '100%' }}
                />
              ) : null}
            </div>
            {state.current.notes ? (
              <div className="col-12">
                <hr />
                <p style={{ whiteSpace: 'pre' }}>{state.current.notes}</p>
              </div>
            ) : null}
            {(state.current.images || []).length ? (
              <>
                <div className="col-12">
                  <hr />
                  <div>
                    {' '}
                    <b>Images</b>
                  </div>
                </div>
                <div className="col-12">
                  {(state.current.images || []).map((img, i) => (
                    <img
                      key={i}
                      src={img.getUrl()}
                      alt="true"
                      style={{ width: '47%', margin: 5 }}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </Dialog>
    </div>
  );
};

export default connectToStore(CommandeForm);
